export const NEGOTIATORS = [
  { field: "Erika", report: true },
  { field: "Irvin", report: true },
  { field: "Jason", report: true },
  { field: "Mary", report: true },
  { field: "Rommel", report: true },
  { field: "Ross" },
  { field: "Trisha", report: true },
].map((n) => ({ ...n, label: n.field }));

export const GROUPS_PER_URL = 5;

export const URLS_RESOURCE_NAME = "urls";

export const URL_GROUPS_RESOURCE_NAME = "url_groups";

export const CHECK_DISABLED_REASONS = [
  { field: "checked_manually", label: "Checked manually" },
  { field: "in_review", label: "In review" },
];

export const FAILURES_WARNING_THRESHOLD = 3;
export const CONSECUTIVE_FAILURES_WARNING_THRESHOLD = 2;
